import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { appRoutesNames } from 'src/app/routes/app.routes.names';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, TranslocoPipe],
})
export class SidebarComponent {
  @Input() hasToggleButton: boolean | undefined;
  @ViewChild('sidebarDrawer') private sidebar: ElementRef | undefined;

  constructor(private readonly router: Router) {}

  dashboardButtonClick() {
    this.router.navigate([appRoutesNames.DASHBOARD]);
  }

  newsBlogButtonClick() {
    this.router.navigate([appRoutesNames.NEWS]);
  }

  assessmentsButtonClick() {
    this.router.navigate([appRoutesNames.ASSESSMENTS]);
  }

  userManagementButtonClick() {
    this.router.navigate([appRoutesNames.USERS]);
  }

  toggleSidebar() {
    const isChecked = this.sidebar?.nativeElement.checked;
    if (this.sidebar) this.sidebar.nativeElement.checked = !isChecked;
  }
}
