/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { EntityModelQuestionnaireCategoryDetailsDto } from '../model/entityModelQuestionnaireCategoryDetailsDto';
// @ts-ignore
import { PagedModelQuestionnaireCategoryDto } from '../model/pagedModelQuestionnaireCategoryDto';
// @ts-ignore
import { QuestionnaireCategoryDto } from '../model/questionnaireCategoryDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateQuestionnaireCategoryRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryDto: QuestionnaireCategoryDto;
}

export interface DeleteQuestionnaireCategoryRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
}

export interface GetQuestionnaireCategoriesRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    /** One-based page index (1..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: Array<string>;
}

export interface GetQuestionnaireCategoryRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
}

export interface UpdateQuestionnaireCategoryRequestParams {
    xTenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireCategoryDto: QuestionnaireCategoryDto;
}


@Injectable({
  providedIn: 'root'
})
export class QuestionnaireCategoriesService {

    protected basePath = 'http://localhost:8420';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createQuestionnaireCategory(requestParameters: CreateQuestionnaireCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelQuestionnaireCategoryDetailsDto>;
    public createQuestionnaireCategory(requestParameters: CreateQuestionnaireCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelQuestionnaireCategoryDetailsDto>>;
    public createQuestionnaireCategory(requestParameters: CreateQuestionnaireCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelQuestionnaireCategoryDetailsDto>>;
    public createQuestionnaireCategory(requestParameters: CreateQuestionnaireCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling createQuestionnaireCategory.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling createQuestionnaireCategory.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling createQuestionnaireCategory.');
        }
        const questionnaireCategoryDto = requestParameters.questionnaireCategoryDto;
        if (questionnaireCategoryDto === null || questionnaireCategoryDto === undefined) {
            throw new Error('Required parameter questionnaireCategoryDto was null or undefined when calling createQuestionnaireCategory.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories`;
        return this.httpClient.request<EntityModelQuestionnaireCategoryDetailsDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: questionnaireCategoryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteQuestionnaireCategory(requestParameters: DeleteQuestionnaireCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public deleteQuestionnaireCategory(requestParameters: DeleteQuestionnaireCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public deleteQuestionnaireCategory(requestParameters: DeleteQuestionnaireCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public deleteQuestionnaireCategory(requestParameters: DeleteQuestionnaireCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling deleteQuestionnaireCategory.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling deleteQuestionnaireCategory.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling deleteQuestionnaireCategory.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling deleteQuestionnaireCategory.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<string>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionnaireCategories(requestParameters: GetQuestionnaireCategoriesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PagedModelQuestionnaireCategoryDto>;
    public getQuestionnaireCategories(requestParameters: GetQuestionnaireCategoriesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PagedModelQuestionnaireCategoryDto>>;
    public getQuestionnaireCategories(requestParameters: GetQuestionnaireCategoriesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PagedModelQuestionnaireCategoryDto>>;
    public getQuestionnaireCategories(requestParameters: GetQuestionnaireCategoriesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getQuestionnaireCategories.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling getQuestionnaireCategories.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling getQuestionnaireCategories.');
        }
        const page = requestParameters.page;
        const size = requestParameters.size;
        const sort = requestParameters.sort;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sort) {
            sort.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'sort');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories`;
        return this.httpClient.request<PagedModelQuestionnaireCategoryDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getQuestionnaireCategory(requestParameters: GetQuestionnaireCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelQuestionnaireCategoryDetailsDto>;
    public getQuestionnaireCategory(requestParameters: GetQuestionnaireCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelQuestionnaireCategoryDetailsDto>>;
    public getQuestionnaireCategory(requestParameters: GetQuestionnaireCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelQuestionnaireCategoryDetailsDto>>;
    public getQuestionnaireCategory(requestParameters: GetQuestionnaireCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getQuestionnaireCategory.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling getQuestionnaireCategory.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling getQuestionnaireCategory.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling getQuestionnaireCategory.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelQuestionnaireCategoryDetailsDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateQuestionnaireCategory(requestParameters: UpdateQuestionnaireCategoryRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelQuestionnaireCategoryDetailsDto>;
    public updateQuestionnaireCategory(requestParameters: UpdateQuestionnaireCategoryRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelQuestionnaireCategoryDetailsDto>>;
    public updateQuestionnaireCategory(requestParameters: UpdateQuestionnaireCategoryRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelQuestionnaireCategoryDetailsDto>>;
    public updateQuestionnaireCategory(requestParameters: UpdateQuestionnaireCategoryRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling updateQuestionnaireCategory.');
        }
        const questionnaireUuid = requestParameters.questionnaireUuid;
        if (questionnaireUuid === null || questionnaireUuid === undefined) {
            throw new Error('Required parameter questionnaireUuid was null or undefined when calling updateQuestionnaireCategory.');
        }
        const questionnaireStageUuid = requestParameters.questionnaireStageUuid;
        if (questionnaireStageUuid === null || questionnaireStageUuid === undefined) {
            throw new Error('Required parameter questionnaireStageUuid was null or undefined when calling updateQuestionnaireCategory.');
        }
        const questionnaireCategoryUuid = requestParameters.questionnaireCategoryUuid;
        if (questionnaireCategoryUuid === null || questionnaireCategoryUuid === undefined) {
            throw new Error('Required parameter questionnaireCategoryUuid was null or undefined when calling updateQuestionnaireCategory.');
        }
        const questionnaireCategoryDto = requestParameters.questionnaireCategoryDto;
        if (questionnaireCategoryDto === null || questionnaireCategoryDto === undefined) {
            throw new Error('Required parameter questionnaireCategoryDto was null or undefined when calling updateQuestionnaireCategory.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/questionnaires/${this.configuration.encodeParam({name: "questionnaireUuid", value: questionnaireUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "questionnaireStageUuid", value: questionnaireStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/categories/${this.configuration.encodeParam({name: "questionnaireCategoryUuid", value: questionnaireCategoryUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelQuestionnaireCategoryDetailsDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: questionnaireCategoryDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
