import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { Observable } from 'rxjs';
import {
  AuthService,
  DomService,
  LocalStorageService,
} from 'src/core/services';
import { AvatarComponent } from '../avatar/avatar.component';
import { LanguageSelectComponent } from '../language-select/language-select.component';
import { UserProfileMenuComponent } from '../user-profile-menu/user-profile-menu.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslocoPipe,
    LanguageSelectComponent,
    AvatarComponent,
    NgOptimizedImage,
    UserProfileMenuComponent,
  ],
})
export class NavbarComponent {
  isAuthenticated$: Observable<boolean>;
  isDarkTheme = false;

  @Input() loading: boolean | undefined;
  @Input() hasSideBar: boolean | undefined;
  @Output() toggleSideBarEvent = new EventEmitter<void>();
  @Output() editProfileEvent = new EventEmitter<void>();

  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    public readonly authService: AuthService,
    private readonly domService: DomService,
  ) {
    this.isAuthenticated$ = this.authService.isAuthenticated$();
    this.isDarkTheme = this.localStorageService.getTheme() === 'dark';
  }

  onThemeChange(event: Event) {
    this.localStorageService.setTheme(
      (event.target as HTMLInputElement).checked ? 'dark' : 'light',
    );
    this.domService.setHtmlAttribute(
      'data-theme',
      this.localStorageService.getTheme(),
    );
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  toggleSidebar() {
    if (this.hasSideBar) {
      this.toggleSideBarEvent.emit();
    }
  }
}
