import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GitStatus,
  GitStatusService,
} from 'src/core/services/git-status.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-environment-information',
  templateUrl: './environment-information.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe],
})
export class EnvironmentInformationComponent {
  environment = environment;
  backgroundColor = this.getBackgroundColor();
  textColor = this.getTextColor();
  gitStatus$: Observable<GitStatus>;

  constructor(private readonly gitStatusService: GitStatusService) {
    this.gitStatus$ = this.gitStatusService.getGitStatus$();
  }

  getBackgroundColor() {
    switch (environment.name) {
      case 'staging':
        return 'bg-red-400';
      case 'testing':
        return 'bg-yellow-200';
      default:
        return 'bg-blue-100';
    }
  }

  getTextColor() {
    return environment.name === 'staging' ? 'text-white' : 'text-black';
  }
}
