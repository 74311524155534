/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { AssessmentStageDto } from '../model/assessmentStageDto';
// @ts-ignore
import { EntityModelAssessmentStageDetailsDto } from '../model/entityModelAssessmentStageDetailsDto';
// @ts-ignore
import { PagedModelAssessmentStageDto } from '../model/pagedModelAssessmentStageDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateAssessmentStageRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageDto: AssessmentStageDto;
}

export interface DeleteAssessmentStageRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
}

export interface GetAssessmentStageRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
}

export interface GetAssessmentStagesRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    /** One-based page index (1..N) */
    page?: number;
    /** The size of the page to be returned */
    size?: number;
    /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
    sort?: Array<string>;
}

export interface UpdateAssessmentStageRequestParams {
    xTenantUuid: string;
    xUserUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentStageDto: AssessmentStageDto;
}


@Injectable({
  providedIn: 'root'
})
export class AssessmentStagesService {

    protected basePath = 'http://localhost:8420';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createAssessmentStage(requestParameters: CreateAssessmentStageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelAssessmentStageDetailsDto>;
    public createAssessmentStage(requestParameters: CreateAssessmentStageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelAssessmentStageDetailsDto>>;
    public createAssessmentStage(requestParameters: CreateAssessmentStageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelAssessmentStageDetailsDto>>;
    public createAssessmentStage(requestParameters: CreateAssessmentStageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling createAssessmentStage.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling createAssessmentStage.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling createAssessmentStage.');
        }
        const assessmentStageDto = requestParameters.assessmentStageDto;
        if (assessmentStageDto === null || assessmentStageDto === undefined) {
            throw new Error('Required parameter assessmentStageDto was null or undefined when calling createAssessmentStage.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages`;
        return this.httpClient.request<EntityModelAssessmentStageDetailsDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: assessmentStageDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssessmentStage(requestParameters: DeleteAssessmentStageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<string>;
    public deleteAssessmentStage(requestParameters: DeleteAssessmentStageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<string>>;
    public deleteAssessmentStage(requestParameters: DeleteAssessmentStageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<string>>;
    public deleteAssessmentStage(requestParameters: DeleteAssessmentStageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling deleteAssessmentStage.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling deleteAssessmentStage.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling deleteAssessmentStage.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling deleteAssessmentStage.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<string>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssessmentStage(requestParameters: GetAssessmentStageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelAssessmentStageDetailsDto>;
    public getAssessmentStage(requestParameters: GetAssessmentStageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelAssessmentStageDetailsDto>>;
    public getAssessmentStage(requestParameters: GetAssessmentStageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelAssessmentStageDetailsDto>>;
    public getAssessmentStage(requestParameters: GetAssessmentStageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getAssessmentStage.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling getAssessmentStage.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling getAssessmentStage.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling getAssessmentStage.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelAssessmentStageDetailsDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssessmentStages(requestParameters: GetAssessmentStagesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<PagedModelAssessmentStageDto>;
    public getAssessmentStages(requestParameters: GetAssessmentStagesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PagedModelAssessmentStageDto>>;
    public getAssessmentStages(requestParameters: GetAssessmentStagesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PagedModelAssessmentStageDto>>;
    public getAssessmentStages(requestParameters: GetAssessmentStagesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling getAssessmentStages.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling getAssessmentStages.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling getAssessmentStages.');
        }
        const page = requestParameters.page;
        const size = requestParameters.size;
        const sort = requestParameters.sort;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (page !== undefined && page !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>page, 'page');
        }
        if (size !== undefined && size !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>size, 'size');
        }
        if (sort) {
            sort.forEach((element) => {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                  <any>element, 'sort');
            })
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages`;
        return this.httpClient.request<PagedModelAssessmentStageDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateAssessmentStage(requestParameters: UpdateAssessmentStageRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<EntityModelAssessmentStageDetailsDto>;
    public updateAssessmentStage(requestParameters: UpdateAssessmentStageRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<EntityModelAssessmentStageDetailsDto>>;
    public updateAssessmentStage(requestParameters: UpdateAssessmentStageRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<EntityModelAssessmentStageDetailsDto>>;
    public updateAssessmentStage(requestParameters: UpdateAssessmentStageRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        const xTenantUuid = requestParameters.xTenantUuid;
        if (xTenantUuid === null || xTenantUuid === undefined) {
            throw new Error('Required parameter xTenantUuid was null or undefined when calling updateAssessmentStage.');
        }
        const xUserUuid = requestParameters.xUserUuid;
        if (xUserUuid === null || xUserUuid === undefined) {
            throw new Error('Required parameter xUserUuid was null or undefined when calling updateAssessmentStage.');
        }
        const assessmentUuid = requestParameters.assessmentUuid;
        if (assessmentUuid === null || assessmentUuid === undefined) {
            throw new Error('Required parameter assessmentUuid was null or undefined when calling updateAssessmentStage.');
        }
        const assessmentStageUuid = requestParameters.assessmentStageUuid;
        if (assessmentStageUuid === null || assessmentStageUuid === undefined) {
            throw new Error('Required parameter assessmentStageUuid was null or undefined when calling updateAssessmentStage.');
        }
        const assessmentStageDto = requestParameters.assessmentStageDto;
        if (assessmentStageDto === null || assessmentStageDto === undefined) {
            throw new Error('Required parameter assessmentStageDto was null or undefined when calling updateAssessmentStage.');
        }

        let localVarHeaders = this.defaultHeaders;
        if (xTenantUuid !== undefined && xTenantUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-tenant-uuid', String(xTenantUuid));
        }
        if (xUserUuid !== undefined && xUserUuid !== null) {
            localVarHeaders = localVarHeaders.set('x-user-uuid', String(xUserUuid));
        }

        let localVarCredential: string | undefined;
        // authentication (bearer-key) required
        localVarCredential = this.configuration.lookupCredential('bearer-key');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/v1/assessments/${this.configuration.encodeParam({name: "assessmentUuid", value: assessmentUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}/stages/${this.configuration.encodeParam({name: "assessmentStageUuid", value: assessmentStageUuid, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: "uuid"})}`;
        return this.httpClient.request<EntityModelAssessmentStageDetailsDto>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: assessmentStageDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}
