import { NgClass, NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { LanguageService } from 'src/core/services/language.service';
import { DropdownComponent } from '../elements/dropdown/dropdown.component';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgFor, NgClass, DropdownComponent],
})
export class LanguageSelectComponent {
  availableLanguages = [...this.languageService.availableLanguages];

  @ViewChild(DropdownComponent) dropdownComponent!: DropdownComponent;

  constructor(public readonly languageService: LanguageService) {}

  changeLanguage(language: string) {
    this.languageService.setLanguage(language);
    this.dropdownComponent.closeDropdown();
  }
}
