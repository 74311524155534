import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomService, LocalStorageService } from 'src/core/services';
import { FooterComponent } from '../shared/layout/footer/footer.component';
import { HeaderComponent } from '../shared/layout/header/header.component';
import { MainComponent } from '../shared/layout/main/main.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FooterComponent, HeaderComponent, MainComponent],
})
export class AppComponent implements OnInit {
  constructor(
    private readonly domService: DomService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  ngOnInit() {
    this.domService.setHtmlAttribute(
      'data-theme',
      this.localStorageService.getTheme()
        ? this.localStorageService.getTheme()
        : 'light',
    );
  }
}
