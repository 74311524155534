export * from './assessmentAnswers.service';
import { AssessmentAnswersService } from './assessmentAnswers.service';
export * from './assessmentCategories.service';
import { AssessmentCategoriesService } from './assessmentCategories.service';
export * from './assessmentQuestions.service';
import { AssessmentQuestionsService } from './assessmentQuestions.service';
export * from './assessmentStages.service';
import { AssessmentStagesService } from './assessmentStages.service';
export * from './assessmentStatements.service';
import { AssessmentStatementsService } from './assessmentStatements.service';
export * from './assessments.service';
import { AssessmentsService } from './assessments.service';
export * from './echo.service';
import { EchoService } from './echo.service';
export * from './questionnaireAnswers.service';
import { QuestionnaireAnswersService } from './questionnaireAnswers.service';
export * from './questionnaireCategories.service';
import { QuestionnaireCategoriesService } from './questionnaireCategories.service';
export * from './questionnaireQuestions.service';
import { QuestionnaireQuestionsService } from './questionnaireQuestions.service';
export * from './questionnaireStages.service';
import { QuestionnaireStagesService } from './questionnaireStages.service';
export * from './questionnaireStatementAnswers.service';
import { QuestionnaireStatementAnswersService } from './questionnaireStatementAnswers.service';
export * from './questionnaireStatements.service';
import { QuestionnaireStatementsService } from './questionnaireStatements.service';
export * from './questionnaires.service';
import { QuestionnairesService } from './questionnaires.service';
export const APIS = [AssessmentAnswersService, AssessmentCategoriesService, AssessmentQuestionsService, AssessmentStagesService, AssessmentStatementsService, AssessmentsService, EchoService, QuestionnaireAnswersService, QuestionnaireCategoriesService, QuestionnaireQuestionsService, QuestionnaireStagesService, QuestionnaireStatementAnswersService, QuestionnaireStatementsService, QuestionnairesService];
