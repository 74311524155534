/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ComponentRef,
  Injectable,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private viewContainerRef: ViewContainerRef | undefined;

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }

  open(
    component: Type<any>,
    config?: any,
  ): { onClose: Observable<unknown>; componentRef: ComponentRef<unknown> } {
    const componentRef = this.viewContainerRef?.createComponent(component);

    if (config && config.context) {
      Object.assign(componentRef?.instance, config.context);
    }

    if (!(componentRef?.instance.onClose instanceof Observable)) {
      throw new Error('Component does not have an onClose Observable!');
    }

    return {
      onClose: componentRef?.instance.onClose,
      componentRef: componentRef,
    };
  }
}
