/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';


export interface QuestionnaireQuestionDto { 
    uuid?: string;
    tenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    type: QuestionnaireQuestionDto.TypeEnum;
    question?: string;
    description?: string;
    order?: number;
    weight?: number;
    links?: Array<Link>;
    normalizeStatementWeights?: boolean;
}
export namespace QuestionnaireQuestionDto {
    export type TypeEnum = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE' | 'LICKERT_SCALE';
    export const TypeEnum = {
        SingleChoice: 'SINGLE_CHOICE' as TypeEnum,
        MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum,
        LickertScale: 'LICKERT_SCALE' as TypeEnum
    };
}


