import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentInformationComponent } from '../../environment-information/environment-information.component';
import { NavbarComponent } from '../../navbar/navbar.component';
import { SidebarComponent } from '../../sidebar/sidebar.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [EnvironmentInformationComponent, NavbarComponent],
})
export class HeaderComponent {
  @ViewChild(SidebarComponent) sidebar!: SidebarComponent;

  environment = environment;

  constructor() {}

  toggleSidebar() {
    this.sidebar?.toggleSidebar();
  }
}
