import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export type GitStatus = {
  branch: string;
  commitHash: string;
  commitMessage: string;
};

@Injectable({
  providedIn: 'root',
})
export class GitStatusService {
  private gitStatusJsonUrl = 'assets/git-status.json';

  constructor(private httpClient: HttpClient) {}

  getGitStatus$(): Observable<GitStatus> {
    return this.httpClient.get<GitStatus>(this.gitStatusJsonUrl);
  }
}
