/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { QuestionnaireAnswerDetailsDto } from './questionnaireAnswerDetailsDto';
import { QuestionnaireStatementDetailsDto } from './questionnaireStatementDetailsDto';
import { Link } from './link';


export interface EntityModelQuestionnaireQuestionDetailsDto { 
    uuid?: string;
    tenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    type: EntityModelQuestionnaireQuestionDetailsDto.TypeEnum;
    question?: string;
    description?: string;
    order?: number;
    weight?: number;
    answers?: Array<QuestionnaireAnswerDetailsDto>;
    statements?: Array<QuestionnaireStatementDetailsDto>;
    links?: Array<Link>;
    normalizeStatementWeights?: boolean;
}
export namespace EntityModelQuestionnaireQuestionDetailsDto {
    export type TypeEnum = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE' | 'LICKERT_SCALE';
    export const TypeEnum = {
        SingleChoice: 'SINGLE_CHOICE' as TypeEnum,
        MultipleChoice: 'MULTIPLE_CHOICE' as TypeEnum,
        LickertScale: 'LICKERT_SCALE' as TypeEnum
    };
}


