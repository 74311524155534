/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';


export interface QuestionnaireAnswerDto { 
    uuid?: string;
    tenantUuid: string;
    questionnaireUuid: string;
    questionnaireStageUuid: string;
    questionnaireCategoryUuid: string;
    questionnaireQuestionUuid: string;
    answer?: string;
    order?: number;
    recommendation?: string;
    score?: number;
    links?: Array<Link>;
    isDefaultAnswer?: boolean;
    excludeQuestionFromScoring?: boolean;
}

