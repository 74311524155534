import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfilePictureService {
  private profilePictureSubject = new BehaviorSubject<string | null>(null);
  profilePicture$ = this.profilePictureSubject.asObservable();

  setProfilePicture(profilePicture: string) {
    this.profilePictureSubject.next(profilePicture);
  }
}
