<div [ngClass]="ngDropdownClass">
  <div
    #dropdownButtonElement
    role="button"
    [ngClass]="ngButtonClass"
    tabindex="0"
    (click)="toggleDropdown()"
    (keypress)="toggleDropdown()"
  >
    <ng-container *ngIf="buttonLabel; else dropdownButtonTemplate">
      {{ buttonLabel }}
    </ng-container>
    <ng-template #dropdownButtonTemplate>
      <ng-content select="[dropdown-button]"></ng-content>
    </ng-template>
  </div>
  <div #dropdownContentElement [ngClass]="ngContentClass">
    <ng-content select="[dropdown-content]"></ng-content>
  </div>
</div>
