import { Injectable } from '@angular/core';
import TurndownService from 'turndown';

@Injectable({
  providedIn: 'root',
})
export class HtmlToMarkdownService {
  private turndownService = new TurndownService();

  convert(html: string): string {
    return this.turndownService.turndown(html);
  }
}
