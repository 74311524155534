@if (gitStatus$ | async; as gitStatus) {
  <div
    class="flex w-full flex-col text-center px-1 font-thin text-xs md:flex-row md:items-center md:justify-center md:gap-1 
    {{ backgroundColor }} {{ textColor }}
    "
  >
    <div class="w-full truncate font-normal md:w-auto md:min-w-fit">
      {{ environment.name }}
    </div>
    <div class="hidden md:block">•</div>
    <div
      class="flex w-full justify-center gap-1 truncate md:w-auto md:min-w-fit md:flex-row"
    >
      <div class="w-auto min-w-fit truncate">
        {{ gitStatus.commitHash }}
      </div>
      <div>•</div>
      <div class="w-auto truncate">
        {{ gitStatus.branch }}
      </div>
    </div>
    <div class="hidden md:block">•</div>
    <div class="w-full truncate md:w-auto">
      {{ gitStatus.commitMessage }}
    </div>
  </div>
}
