/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentBenchmarkStageDto } from './assessmentBenchmarkStageDto';
import { Link } from './link';


export interface EntityModelAssessmentBenchmarkDto { 
    tenantUuid: string;
    userUuid: string;
    questionnaireUuid: string;
    assessmentUuid: string;
    name?: string;
    questionnaireAverageScore?: number;
    assessmentScore?: number;
    stages?: Array<AssessmentBenchmarkStageDto>;
    links?: Array<Link>;
}

