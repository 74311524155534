import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { appRoutesNames } from 'src/app/routes/app.routes.names';
import { AuthService } from 'src/core/services';
import { AvatarComponent } from '../avatar/avatar.component';
import { DropdownComponent } from '../elements/dropdown/dropdown.component';

@Component({
  selector: 'app-user-profile-menu',
  templateUrl: './user-profile-menu.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoPipe, DropdownComponent, AvatarComponent],
})
export class UserProfileMenuComponent {
  @ViewChild(DropdownComponent) dropdownComponent!: DropdownComponent;

  constructor(
    public readonly router: Router,
    public readonly authService: AuthService,
  ) {}

  navigateToEditProfile() {
    this.router.navigate([appRoutesNames.PROFILE]);
    this.dropdownComponent.closeDropdown();
  }

  signOut() {
    this.authService.logout();
    this.dropdownComponent.closeDropdown();
  }
}
