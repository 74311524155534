import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { Subject, distinctUntilChanged, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomTitleStrategy extends TitleStrategy {
  private title = inject(Title);
  private translocoService = inject(TranslocoService);

  private readonly title$ = new Subject<string>();

  constructor() {
    super();
    this.title$
      .pipe(
        takeUntilDestroyed(),
        distinctUntilChanged(),
        switchMap((title) => this.translocoService.selectTranslate(title)),
      )
      .subscribe((translatedTitle) => {
        this.title.setTitle(translatedTitle);
      });
  }

  setTitle(title: string): void {
    this.title$.next(title);
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    if (title) {
      this.title$.next(title);
    } else {
      this.title$.next('');
    }
  }
}
