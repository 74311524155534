/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Provider } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

const jwtInterceptorFactory = (authService: AuthService): HttpInterceptor => {
  let isRefreshing = false;
  const accessTokenSubject: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);

  return {
    intercept: (
      request: HttpRequest<any>,
      next: HttpHandler,
    ): Observable<HttpEvent<any>> => {
      const addToken = (
        request: HttpRequest<any>,
        token: string | undefined,
      ) => {
        if (token) {
          return request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return request;
      };

      const handle401Error = (request: HttpRequest<any>, next: HttpHandler) => {
        if (!isRefreshing) {
          isRefreshing = true;
          accessTokenSubject.next(undefined);

          return authService.refresh().pipe(
            switchMap((tokenResponseDto) => {
              isRefreshing = false;
              accessTokenSubject.next(tokenResponseDto?.accessToken);
              return next.handle(
                addToken(request, tokenResponseDto?.accessToken),
              );
            }),
            catchError((error) => {
              isRefreshing = false;
              if (error instanceof HttpErrorResponse && error.status === 401) {
                authService.logout();
              }
              return throwError(() => error);
            }),
          );
        }

        return accessTokenSubject.pipe(
          filter((token) => token != undefined),
          take(1),
          switchMap((token) => next.handle(addToken(request, token))),
        );
      };

      let token = authService.currentAccessTokenValue;
      if (request.url.includes('/auth/refresh')) {
        token = authService.currentRefreshTokenValue;
      }

      return next.handle(addToken(request, token)).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            if (request.url.includes('/auth/refresh')) {
              authService.logout();
              return throwError(() => error);
            }
            if (request.url.includes('/auth/')) {
              return throwError(() => error);
            }
            return handle401Error(request, next);
          }
          return throwError(() => error);
        }),
      );
    },
  };
};

export const jwtInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useFactory: jwtInterceptorFactory,
  deps: [AuthService],
  multi: true,
};
