/**
 * Blueprint Application NestJS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserDto } from './userDto';


export interface BlogEntryDto { 
    uuid: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    published: boolean;
    title: string;
    content: string | null;
    teaserImageUuid: string | null;
    blogUuid: string;
    authorUuid: string | null;
    author?: UserDto | null;
}

