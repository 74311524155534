/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AssessmentCategoryDetailsDto } from './assessmentCategoryDetailsDto';
import { AssessmentStageDetailsDto } from './assessmentStageDetailsDto';
import { Link } from './link';


export interface EntityModelAssessmentStageDetailsDto { 
    uuid?: string;
    tenantUuid: string;
    userUuid: string;
    assessmentUuid: string;
    parentAssessmentStageUuid?: string;
    questionnaireStageUuid: string;
    questionnaireStageName?: string;
    questionnaireStageOrder?: number;
    questionnaireStageWeight?: number;
    subject?: string;
    score?: number;
    progress?: number;
    categories?: Array<AssessmentCategoryDetailsDto>;
    childStages?: Array<AssessmentStageDetailsDto>;
    links?: Array<Link>;
}

