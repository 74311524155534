import { isPlatformBrowser } from '@angular/common';
import {
  Injectable,
  PLATFORM_ID,
  Renderer2,
  RendererFactory2,
  inject,
} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  private rendererFactory = inject(RendererFactory2);
  private platformId = inject(PLATFORM_ID);

  private renderer: Renderer2;

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  setHtmlAttribute(attributeName: string, attributeValue: string) {
    if (isPlatformBrowser(this.platformId)) {
      const htmlElement = document.documentElement;
      this.renderer.setAttribute(htmlElement, attributeName, attributeValue);
    }
  }
}
