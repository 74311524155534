/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';


export interface AssessmentQuestionDto { 
    uuid?: string;
    tenantUuid: string;
    userUuid: string;
    assessmentUuid: string;
    assessmentStageUuid: string;
    assessmentCategoryUuid: string;
    questionnaireQuestionUuid: string;
    questionnaireQuestionType: AssessmentQuestionDto.QuestionnaireQuestionTypeEnum;
    questionnaireQuestionQuestion?: string;
    questionnaireQuestionDescription?: string;
    questionnaireQuestionOrder?: number;
    questionnaireQuestionWeight?: number;
    score?: number;
    progress?: number;
    links?: Array<Link>;
    questionnaireQuestionNormalizeStatementWeights?: boolean;
}
export namespace AssessmentQuestionDto {
    export type QuestionnaireQuestionTypeEnum = 'SINGLE_CHOICE' | 'MULTIPLE_CHOICE' | 'LICKERT_SCALE';
    export const QuestionnaireQuestionTypeEnum = {
        SingleChoice: 'SINGLE_CHOICE' as QuestionnaireQuestionTypeEnum,
        MultipleChoice: 'MULTIPLE_CHOICE' as QuestionnaireQuestionTypeEnum,
        LickertScale: 'LICKERT_SCALE' as QuestionnaireQuestionTypeEnum
    };
}


