<header>
  @if (environment.showEnvironmentInformation) {
    <app-environment-information />
  }

  <app-navbar
    (toggleSideBarEvent)="toggleSidebar()"
    [hasSideBar]="true"
    [loading]="false"
  />
</header>
