/**
 * Blueprint Application NestJS Backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GroupDto } from './groupDto';


export interface UserProfileDto { 
    uuid: string;
    createdAt: string;
    updatedAt: string;
    email: string;
    isVerified: boolean;
    isActive: boolean;
    isAdmin: boolean;
    profileImageUuid: string | null;
    title: string | null;
    firstName: string | null;
    lastName: string | null;
    companyName: string | null;
    sector: string | null;
    businessUnit: string | null;
    lang: string | null;
    tenantUuid: string;
    fullName: string | null;
    groups?: Array<GroupDto>;
}

