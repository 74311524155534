/**
 * INC Assessment Service
 * INC Assessment Service
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Link } from './link';


export interface AssessmentDto { 
    uuid?: string;
    tenantUuid: string;
    userUuid: string;
    questionnaireUuid: string;
    questionnaireName?: string;
    name?: string;
    score?: number;
    progress?: number;
    links?: Array<Link>;
    isPublished?: boolean;
    isDeleted?: boolean;
}

